import {Box, Button} from "@mui/material";
import {CustomDialog, useLocales} from "rentzz";
import {useState} from "react";
import Terms from "../Terms.tsx";
import PrivacyPolicy from "../PrivacyPolicy.tsx";

enum DialogType {
    None,
    TOS,
    Policies
}

const PoliciesRow = () => {
    const {translate} = useLocales()
    const [dialogType, setDialogType] = useState(DialogType.None)
    return <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Button onClick={()=> setDialogType(DialogType.TOS)} variant="text">{translate("terms_and_conditions")}</Button>
        <Button onClick={()=> setDialogType(DialogType.Policies)} variant="text">{translate("privacy_policy")}</Button>

        <CustomDialog maxWidth="md" open={dialogType !== DialogType.None} onClose={() => setDialogType(DialogType.None)} isFullScreen={false}>
            {dialogType === DialogType.TOS ? <Terms /> : <PrivacyPolicy />}
        </CustomDialog>
    </Box>
}

export default PoliciesRow