const TermsAndConditions = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <h1>Termeni și Condiții</h1>
            <p>
                Acest document stabilește termenii și condițiile de utilizare a
                serviciilor furnizate de <strong>MYCO SOLUTIONS SRL</strong> („Compania”),
                o platformă care permite utilizatorilor să efectueze plăți către
                furnizorii de utilități. Prin utilizarea platformei, sunteți de acord să
                respectați acești termeni și condiții.
            </p>

            <h2>1. Definiții</h2>
            <ul>
                <li>
                    <strong>Platforma:</strong> Aplicația sau site-ul web pus la
                    dispoziție de Companie pentru efectuarea plăților către furnizorii de
                    utilități.
                </li>
                <li>
                    <strong>Utilizator:</strong> Orice persoană fizică sau juridică care
                    utilizează serviciile platformei.
                </li>
                <li>
                    <strong>Furnizor de utilități:</strong> Companii sau entități care
                    furnizează servicii esențiale, precum apă, energie electrică, gaze
                    naturale, telecomunicații etc.
                </li>
                <li>
                    <strong>Tranzacție:</strong> Transferul de bani efectuat de Utilizator
                    prin intermediul platformei pentru a plăti serviciile furnizorilor de
                    utilități.
                </li>
            </ul>

            <h2>2. Obiectul Serviciului</h2>
            <p>
                Compania oferă un serviciu de intermediere prin care preia sumele de
                bani plătite de Utilizatori și le transferă către furnizorii de utilități
                desemnați de Utilizatori. Compania nu furnizează direct servicii de
                utilități și nu este responsabilă pentru calitatea sau disponibilitatea
                acestora.
            </p>

            <h2>3. Înregistrarea și Contul Utilizatorului</h2>
            <ul>
                <li>
                    Utilizatorul este responsabil pentru orice activitate desfășurată în contul său.
                </li>
            </ul>

            <h2>4. Plata și Procesarea Tranzacțiilor</h2>
            <ul>
                <li>
                    Utilizatorul este responsabil pentru furnizarea corectă a detaliilor
                    necesare efectuării plăților către furnizorii de utilități.
                </li>
                <li>
                    Compania poate percepe un comision pentru fiecare tranzacție, care va fi
                    afișat înainte de confirmarea plății.
                </li>
                <li>
                    Compania procesează plățile în termen de <strong>1-2 zile lucrătoare</strong> de la primirea fondurilor.
                </li>
                <li>
                    Orice întârziere în procesarea plăților din cauza unor erori tehnice,
                    bancare sau a unor informații incorecte furnizate de Utilizator nu este
                    responsabilitatea Companiei.
                </li>
            </ul>

            <h2>5. Drepturi și Obligații</h2>
            <h3>5.1 Utilizatorul</h3>
            <ul>
                <li>Are obligația de a furniza informații corecte despre furnizorul de utilități și detaliile plății.</li>
                <li>Este responsabil pentru verificarea corectitudinii datelor furnizate.</li>
            </ul>

            <h3>5.2 Compania</h3>
            <ul>
                <li>Se obligă să transfere fondurile către furnizorii de utilități în termenul stabilit.</li>
                <li>
                    Nu este responsabilă pentru eventualele probleme dintre Utilizator și
                    furnizorii de utilități.
                </li>
            </ul>

            <h2>6. Politica de Rambursare</h2>
            <p>
                În cazul în care plata nu poate fi procesată din motive imputabile
                Companiei, suma plătită de Utilizator va fi returnată în termen de <strong>7
                zile lucrătoare</strong>. Dacă Utilizatorul a furnizat date incorecte,
                rambursarea poate fi refuzată sau întârzierile pot fi imputate
                Utilizatorului.
            </p>

            <h2>7. Limitarea Răspunderii</h2>
            <ul>
                <li>
                    Compania nu este responsabilă pentru întârzierile sau erorile cauzate
                    de terți, cum ar fi bănci, procesatori de plăți sau furnizori de
                    utilități.
                </li>
                <li>
                    În măsura permisă de lege, răspunderea Companiei este limitată la
                    valoarea tranzacției efectuate de Utilizator.
                </li>
            </ul>

            <h2>8. Protecția Datelor</h2>
            <p>
                Compania respectă legislația privind protecția datelor cu caracter
                personal și utilizează informațiile Utilizatorului doar pentru
                procesarea plăților.
            </p>

            <h2>9. Încetarea Serviciului</h2>
            <p>
                Compania își rezervă dreptul de a suspenda sau închide contul unui
                Utilizator care încalcă acești termeni și condiții.
            </p>

            <h2>10. Modificări ale Termenilor și Condițiilor</h2>
            <p>
                Compania își rezervă dreptul de a modifica acești termeni și condiții.
                Utilizatorii vor fi notificați cu privire la orice schimbare
                semnificativă.
            </p>

            <h2>11. Legea Aplicabilă</h2>
            <p>
                Acest acord este guvernat de legile din <strong>România</strong>. Orice dispută va
                fi soluționată de instanțele competente din <strong>București</strong>.
            </p>

            <h2>12. Contact</h2>
            <p>
                Pentru orice întrebări sau nelămuriri, ne puteți contacta la:
                <ul>
                    <li><strong>Email:</strong> contact@plata-utilitati.ro</li>
                </ul>
            </p>

            <p>
                Prin utilizarea platformei, confirmați că ați citit, înțeles și sunteți
                de acord cu acești termeni și condiții.
            </p>
        </div>
    );
};

export default TermsAndConditions;
