import {ReactElement, useEffect} from "react";

export interface ClosingPageProps {
    children: ReactElement
}
const ClosingPage = ({children}: ClosingPageProps) => {
    useEffect(() => {
        setTimeout(() => {
            window.close()
        },5000)
    }, [])

    return children;
}

export default ClosingPage