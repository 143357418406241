import {Box, Divider, Paper, Typography, useTheme} from "@mui/material";
import {Page, useLocales} from "rentzz";
import {useCompanyDetailsQuery, useOrderDetailsQuery} from "../utils.ts";
import {ReactNode} from "react";
import {useSearchParams} from "react-router-dom";
import Buttons from "./Buttons.tsx";
import NTPLogo from "ntp-logo-react";
import PoliciesRow from "./PoliciesRow.tsx";

interface Props {
    content: ReactNode,
    onAction: () => void
    actionText: string
    isBackButton: boolean
    isLoading: boolean
}

export default function DesktopView({content, onAction, actionText, isBackButton, isLoading}: Props) {
    const theme = useTheme()
    const {translate} = useLocales()
    const [search] = useSearchParams()
    const {data: orderDetails} = useOrderDetailsQuery(search.get("code"), search.get("tenantId"))
    const {data: companyData} = useCompanyDetailsQuery(search.get("tenantId"))

    return (
        <Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
            <Page title={search.get("tenantId") ?? ""} propertyName={translate("online_payment")}>
                <Paper elevation={20} sx={{minWidth: window.innerWidth - 400}}>
                    <Box
                        px={3}
                        py={2}
                        display='flex'
                        width='100%'
                        height='100%'
                        justifyContent='space-between'
                        alignItems='center'
                        flexDirection='column'
                        gap={2}
                    >
                        <PoliciesRow />
                        <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={1}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <img src={companyData?.logoUrl} alt='Logo' title='Logo' height={"80"}/>
                                <Box display={"flex"} gap={1} flexDirection="column" alignItems="center">
                                    <NTPLogo color="#ffffff" version="horizontal" secret="145864"/>
                                    <Box display="flex" flexDirection="row" gap={1}>
                                        <img
                                            src={"https://anpc.ro/wp-content/uploads/2022/07/SAL-PICTOGRAMA.png"}
                                            height='60px' alt={""}/>
                                        <img
                                            src={"/anpc.png"}
                                            height='60px' alt={""}/>
                                    </Box>

                                </Box>
                            </Box>
                            <Box display={"flex"} gap={2} width={"100%"}>
                                <Paper
                                    elevation={2}
                                    sx={{display: "flex", flexDirection: "column", p: 1, gap: 1, width: "35%"}}
                                >
                                    <Typography
                                        fontWeight={"bold"}
                                        variant={"caption"}
                                        sx={{color: theme.palette.grey.A700}}>{translate("schedule")}: <span
                                        style={{fontWeight: "normal"}}>{companyData?.schedule}</span>
                                    </Typography>
                                    <Typography
                                        fontWeight={"bold"}
                                        variant={"caption"}
                                        sx={{color: theme.palette.grey.A700}}>{translate("address")}: <span
                                        style={{fontWeight: "normal"}}>{companyData?.location}</span>
                                    </Typography>
                                    <Typography
                                        fontWeight={"bold"}
                                        variant={"caption"}
                                        sx={{color: theme.palette.grey.A700}}>{translate("phone")}: <span
                                        style={{fontWeight: "normal"}}>{companyData?.phone}</span>
                                    </Typography>

                                </Paper>

                                <Paper
                                    elevation={2}
                                    sx={{display: "flex", flexDirection: "column", p: 1, gap: 1, width: "65%"}}
                                >
                                    <Typography
                                        fontWeight={"bold"}
                                        variant={"caption"}
                                        sx={{color: theme.palette.grey.A700}}>{translate("info_card_payment")}
                                    </Typography>
                                    <Divider/>
                                    <Box display={"flex"}>
                                        <Box display={"flex"} width={"50%"} flexDirection={"column"} gap={1}>
                                            <Box display={"flex"} gap={.5}>
                                                <Typography
                                                    fontWeight={"bold"}
                                                    variant={"caption"}
                                                    sx={{color: theme.palette.grey.A700}}
                                                >
                                                    {orderDetails?.firstName}
                                                </Typography>
                                                <Typography
                                                    fontWeight={"bold"}
                                                    variant={"caption"}
                                                    sx={{color: theme.palette.grey.A700}}
                                                >
                                                    {orderDetails?.lastName}
                                                </Typography>
                                            </Box>
                                            <Typography
                                                fontWeight={"bold"}
                                                variant={"caption"}
                                                sx={{color: theme.palette.grey.A700}}
                                            >
                                                {orderDetails?.email}
                                            </Typography>
                                            <Typography
                                                fontWeight={"bold"}
                                                variant={"caption"}
                                                sx={{color: theme.palette.grey.A700}}>{translate("phone")}: <span
                                                style={{fontWeight: "normal"}}>{orderDetails?.phoneNumber}</span>
                                            </Typography>
                                            <Typography
                                                fontWeight={"bold"}
                                                variant={"caption"}
                                                sx={{color: theme.palette.grey.A700}}>{translate("country")}: <span
                                                style={{fontWeight: "normal"}}>{orderDetails?.country}</span>
                                            </Typography>

                                        </Box>
                                        <Box display={"flex"} width={"50%"} flexDirection={"column"} gap={1}><Typography
                                            fontWeight={"bold"}
                                            variant={"caption"}
                                            sx={{color: theme.palette.grey.A700}}>{translate("county")}: <span
                                            style={{fontWeight: "normal"}}>{orderDetails?.county}</span>
                                        </Typography>

                                            <Typography
                                                fontWeight={"bold"}
                                                variant={"caption"}
                                                sx={{color: theme.palette.grey.A700}}>{translate("city")}: <span
                                                style={{fontWeight: "normal"}}>{orderDetails?.city}</span>
                                            </Typography>

                                            <Typography
                                                fontWeight={"bold"}
                                                variant={"caption"}
                                                sx={{color: theme.palette.grey.A700}}>{translate("address")}: <span
                                                style={{fontWeight: "normal"}}>{orderDetails?.address}</span>
                                            </Typography>
                                            <Typography
                                                fontWeight={"bold"}
                                                variant={"caption"}
                                                sx={{color: theme.palette.grey.A700}}>{translate("postalCode")}: <span
                                                style={{fontWeight: "normal"}}>{orderDetails?.zipCode}</span>
                                            </Typography>

                                        </Box>
                                    </Box>


                                </Paper>
                            </Box>
                        </Box>
                        {content}
                        <Buttons isBackButton={isBackButton} actionText={actionText} onAction={onAction} isLoading={isLoading}/>
                        <Typography variant="caption" textAlign="center">MYCO SOLUTIONS SRL. CUI 46600305. Contact: contact@plata-utilitati.ro</Typography>
                    </Box>

                </Paper>
            </Page>
        </Box>
    )
}