import {Box} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useIsMobile, useLocales} from "rentzz";

interface Props {
    isBackButton: boolean
    isLoading: boolean
    actionText: string
    onAction: () => void
}

export default function Buttons({isBackButton, onAction, actionText, isLoading}: Props){
    const {translate} = useLocales()
    const isMobile = useIsMobile()

    return(
        <Box
            sx={{
                alignItems: "center",
                width: "100%",
                display: "flex",
                flexDirection: isMobile ? "column-reverse" : "row",
                gap: 2,
                justifyContent:"center",
                marginBottom: isBackButton ? undefined : 10
            }}
        >
            {isBackButton &&
                <LoadingButton
                    variant={"text"}
                    color={"primary"}
                    fullWidth
                    onClick={() => {
                        if (document.referrer) {
                            window.location.href = document.referrer;
                        } else {
                            window.history.back();
                        }
                    }}
                >
                    {translate("back")}
                </LoadingButton>
            }

            <LoadingButton
                variant={"contained"}
                color={"primary"}
                fullWidth={isBackButton}
                onClick={onAction}
                loading={isLoading}
            >
                {translate(actionText)}
            </LoadingButton>
        </Box>
    )
}