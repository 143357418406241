import axios from "axios"
import {AppContext} from "rentzz"
import i18n from "./locales/i18n.ts"
import {CompanyData, OrderDetails} from "./utils.ts";

export default class Api {
    public static TOKEN = ""
    public static API = "https://test-api.plata-utilitati.ro"
    private static CONTEXT: AppContext | undefined =
        localStorage.getItem("appContext") != null ? (localStorage.getItem("appContext") === "0" ? AppContext.Owner : AppContext.Tenant) : undefined

    public static fetchConfig() {
        return Api.get<Record<string, string>>("/config")
    }

    public static async fetchOrderDetails(key: string | null, tenantId: string | null) {
        return Api.get<OrderDetails>(`/get-payment-details?tenantId=${tenantId}&code=${key}`)
    }

    public static async fetchCompanyDetails(tenantId: string | null) {
        return Api.get<CompanyData>(`/company-config?tenantId=${tenantId}`)
    }

    public static async fetchRedirectPaymentPage(tenantId: string, code: string) {
        return Api.post<never>(`/pay?tenantId=${tenantId}&code=${code}`, {})
    }

    private static async post<T>(path: string, data: unknown, buildConfig = true) {
        const response = await axios.post(`${this.API}${path}`, data, buildConfig ? Api.buildConfig() : undefined)
        return response.data as T
    }
    private static async get<T>(path: string, buildConfig = true) {
        const response = await axios.get(`${this.API}${path}`, buildConfig ? Api.buildConfig() : undefined)
        return response.data as T
    }

    private static buildConfig() {
        if (Api.TOKEN) {
            return {
                headers: {
                    Authorization: `Bearer ${Api.TOKEN}`,
                    "X-CONTEXT": `${Api.CONTEXT}`,
                    "X-LANGUAGE": i18n.language,
                },
            }
        }
    }
}
