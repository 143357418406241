import {Box, Divider, Paper, Typography, useTheme} from "@mui/material";
import {Page, useLocales} from "rentzz";
import {useCompanyDetailsQuery, useOrderDetailsQuery} from "../utils.ts";
import {useSearchParams} from "react-router-dom";
import Buttons from "./Buttons.tsx";
import {ReactNode} from "react";
import NTPLogo from 'ntp-logo-react';

interface Props {
    content: ReactNode,
    onAction: () => void
    actionText: string
    isBackButton: boolean
    isLoading: boolean
}

export default function MobileView({content, actionText, onAction, isBackButton, isLoading}: Props) {
    const {translate} = useLocales()
    const theme = useTheme()
    const [search] = useSearchParams()
    const {data: orderDetails} = useOrderDetailsQuery(search.get("code"), search.get("tenantId"))
    const {data: companyData} = useCompanyDetailsQuery(search.get("tenantId"))

    return (
        <Page title={companyData?.name ?? ""} propertyName={translate("online_payment")}
              sx={{height: "100%", paddingY: 2}}>
            <Box
                px={3}
                py={2}
                display='flex'
                width='100%'
                height={"100%"}
                justifyContent='space-between'
                alignItems='center'
                flexDirection='column'
                gap={2}
            >
                <Box display={"flex"} width={"100%"} flexDirection={"column"} gap={1}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <img src={companyData?.logoUrl} alt='Logo' title='Logo' height={"80"}/>
                    </Box>
                    <NTPLogo color="#ffffff" version="horizontal" secret="145864"/>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <Paper elevation={2} sx={{display: "flex", flexDirection: "column", gap:1, p: 1}}>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("schedule")}: <span
                                style={{fontWeight: "normal"}}>{companyData?.schedule}</span>
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("address")}: <span
                                style={{fontWeight: "normal"}}>{companyData?.location}</span>
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("phone")}: <span
                                style={{fontWeight: "normal"}}>{companyData?.phone}</span>
                            </Typography>
                        </Paper>

                        <Paper elevation={2} sx={{display: "flex", flexDirection: "column", gap:1, p: 1}}>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("info_card_payment")}
                            </Typography>
                            <Divider/>
                            <Box display={"flex"} gap={1}>
                                <Typography
                                    fontWeight={"bold"}
                                    variant={"caption"}
                                    sx={{color: theme.palette.grey.A700}}>{orderDetails?.firstName}
                                </Typography>
                                <Typography
                                    fontWeight={"bold"}
                                    variant={"caption"}
                                    sx={{color: theme.palette.grey.A700}}>{orderDetails?.lastName}
                                </Typography>
                            </Box>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{orderDetails?.email}
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("country")}: <span
                                style={{fontWeight: "normal"}}>{orderDetails?.country}</span>
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("county")}: <span
                                style={{fontWeight: "normal"}}>{orderDetails?.county}</span>
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("city")}: <span
                                style={{fontWeight: "normal"}}>{orderDetails?.city}</span>
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("address")}: <span
                                style={{fontWeight: "normal"}}>{orderDetails?.address}</span>
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("postalCode")}: <span
                                style={{fontWeight: "normal"}}>{orderDetails?.zipCode}</span>
                            </Typography>
                            <Typography
                                fontWeight={"bold"}
                                variant={"caption"}
                                sx={{color: theme.palette.grey.A700}}>{translate("phone")}: <span
                                style={{fontWeight: "normal"}}>{orderDetails?.phoneNumber}</span>
                            </Typography>
                        </Paper>
                    </Box>
                    {content}
                </Box>
                <Buttons isBackButton={isBackButton} actionText={actionText} onAction={onAction} isLoading={isLoading}/>
                <Box display={"flex"} gap={1} flexDirection="column">
                    <img
                        src={"https://anpc.ro/wp-content/uploads/2022/07/SAL-PICTOGRAMA.png"}
                        width='100%'
                        alt={""}/>
                    <img
                        src={"/anpc.png"}
                        width="100%"
                        alt={""}
                    />
                    <Typography variant="caption">MYCO SOLUTIONS SRL. CUI 46600305. Contact: contact@plata-utilitati.ro</Typography>
                </Box>
            </Box>
        </Page>
    )
}