const PrivacyPolicy = () => {
    return (
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
            <h1>Politica de Confidențialitate</h1>

            <section>
                <h2>1. Informații Colectate</h2>
                <p>Aplicația noastră colectează următoarele tipuri de informații personale:</p>
                <ul>
                    <li><strong>Date de contact:</strong> nume, prenume, adresă de e-mail, număr de telefon.</li>
                    <li><strong>Informații despre tranzacții:</strong> valoarea plății, detalii despre furnizorii de utilități.</li>
                    <li><strong>Informații cont:</strong> adresa de facturare sau alte detalii completate de utilizator.</li>
                </ul>
            </section>

            <section>
                <h2>2. Scopul Colectării Datelor</h2>
                <p>Datele personale colectate sunt utilizate pentru:</p>
                <ul>
                    <li>Procesarea plăților către furnizorii de utilități.</li>
                    <li>Emiterea facturilor și confirmarea tranzacțiilor.</li>
                    <li>Contactarea utilizatorilor în caz de probleme legate de tranzacții.</li>
                </ul>
            </section>

            <section>
                <h2>3. Procesarea Plăților Online</h2>
                <p>
                    Pentru a vă oferi opțiunea plății online, folosim platforma de procesare plăți{" "}
                    <strong>NETOPIA PAYMENTS</strong>.
                </p>
                <h3>Detalii privind plata online:</h3>
                <p>
                    Atunci când optați pentru plata online, veți fi redirecționat către platforma{" "}
                    <strong>NETOPIA PAYMENTS</strong>, unde vi se vor solicita date precum:
                </p>
                <ul>
                    <li>Numărul cardului;</li>
                    <li>Numele și prenumele deținătorului cardului;</li>
                    <li>Data expirării cardului;</li>
                    <li>Codul de securitate (CVV).</li>
                </ul>
                <p>
                    Aceste date sunt folosite exclusiv pentru procesarea plății prin NETOPIA PAYMENTS și{" "}
                    <strong>nu ne sunt comunicate direct</strong>.
                </p>

                <h3>Informații transmise către NETOPIA PAYMENTS:</h3>
                <p>
                    În anumite situații, vom transmite către NETOPIA PAYMENTS detalii despre tranzacție, cum ar
                    fi:
                </p>
                <ul>
                    <li>Valoarea tranzacției;</li>
                    <li>Numărul de telefon și adresa de e-mail, dacă acestea au fost completate în contul utilizatorului.</li>
                </ul>

                <h3>Rapoarte primite de la NETOPIA PAYMENTS:</h3>
                <p>
                    Pentru a urmări tranzacțiile și a asigura decontarea plăților, NETOPIA PAYMENTS ne
                    furnizează rapoarte ce pot include date personale precum:
                </p>
                <ul>
                    <li>Nume și prenume;</li>
                    <li>Adresa de e-mail;</li>
                    <li>Numărul de telefon;</li>
                    <li>Adresa de facturare.</li>
                </ul>

                <h3>Probleme legate de plăți:</h3>
                <p>
                    În cazul unor dificultăți legate de procesarea plății, reprezentanții NETOPIA PAYMENTS vă
                    pot contacta direct pentru soluționarea acestora.
                </p>

                <h3>Operator independent:</h3>
                <p>
                    NETOPIA PAYMENTS prelucrează datele personale ale utilizatorilor în calitate de operator
                    independent. Politica lor de confidențialitate poate fi consultată aici:{" "}
                    <a
                        href="https://netopia-payments.com/nota-de-informare-gdpr-platitori/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Politica de Confidențialitate NETOPIA PAYMENTS
                    </a>
                    .
                </p>
            </section>

            <section>
                <h2>4. Partajarea Datelor Personale</h2>
                <p>
                    Datele utilizatorilor pot fi partajate cu terți doar în următoarele cazuri:
                </p>
                <ul>
                    <li>
                        <strong>Furnizori de servicii:</strong> Partajăm datele strict necesare pentru procesarea plăților
                        cu NETOPIA PAYMENTS.
                    </li>
                    <li>
                        <strong>Conformitate legală:</strong> Dacă legea ne obligă să furnizăm date către autorități.
                    </li>
                </ul>
            </section>

            <section>
                <h2>5. Protecția Datelor</h2>
                <p>
                    Luăm măsuri tehnice și organizatorice adecvate pentru a proteja datele personale ale
                    utilizatorilor împotriva accesului neautorizat, utilizării incorecte sau pierderii accidentale.
                </p>
            </section>

            <section>
                <h2>6. Drepturile Utilizatorilor</h2>
                <p>În conformitate cu legislația GDPR, aveți următoarele drepturi:</p>
                <ul>
                    <li>Dreptul de acces la datele dvs.;</li>
                    <li>Dreptul de a solicita rectificarea sau ștergerea datelor personale;</li>
                    <li>Dreptul de a vă opune prelucrării datelor;</li>
                    <li>Dreptul de a depune o plângere la autoritatea competentă pentru protecția datelor.</li>
                </ul>
            </section>

            <section>
                <h2>7. Contact</h2>
                <p>
                    Pentru orice întrebări legate de această politică de confidențialitate sau pentru exercitarea
                    drepturilor dvs., ne puteți contacta la:
                </p>
                <ul>
                    <li><strong>E-mail:</strong> contact@plata-utilitati.ro</li>
                </ul>
            </section>

            <p>
                Prin utilizarea aplicației noastre, confirmați că ați citit și acceptat această politică de confidențialitate.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
