import {Iconify, Page, useLocales} from "rentzz";
import {Box, Typography, useTheme} from "@mui/material";

export default function ErrorPage(){
    const {translate} = useLocales()
    const theme = useTheme()

    return(
        <Page
            title={translate("failed_payment")}
            sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={2}
                 color={theme.palette.grey.A700}>
                <Iconify icon={"material-symbols:error"} sx={{color: theme.palette.error.main}} width={100} height={100}/>
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography variant={"body2"} textAlign={"center"}>{translate("error_payment")}</Typography>
                </Box>
            </Box>
        </Page>
    )
}