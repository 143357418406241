import {alpha, Box, Card, CardContent, Typography, useTheme} from "@mui/material";
import {Iconify} from "rentzz";
import {useSearchParams} from "react-router-dom";
import {useOrderDetailsQuery} from "../utils.ts";

export default function CardsList(){
    const theme= useTheme()
    const [search] = useSearchParams()
    const {data: orderDetails} = useOrderDetailsQuery(search.get("code"),search.get("tenantId"))

    return<>
        {orderDetails?.items.map(item => (
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        px: 1,
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                    }}
                >
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                            <Iconify
                                icon={"mdi:invoice-text-multiple-outline"}
                                width={35}
                                height={35}
                                style={{
                                    borderWidth: 1,
                                    borderColor: alpha(theme.palette.primary.main, 0.1),
                                    borderStyle: "solid",
                                    borderRadius: 50,
                                    padding: 4,
                                    color: theme.palette.primary.main,
                                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                }}
                            />
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography fontWeight={"bold"} fontSize={14}>
                                    {item.title}
                                </Typography>

                                <Typography fontSize={12}>{item.description}</Typography>
                            </Box>
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} alignContent={"center"}
                             justifyContent={"center"}>
                            <Typography textAlign={"center"} fontWeight={"bold"} fontSize={14}>
                                {item.price} {item.currency}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        ))
        }
    </>
}