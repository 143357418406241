import {Box, Container, Paper, Typography} from "@mui/material";
import NTPLogo from "ntp-logo-react";
import PoliciesRow from "./components/PoliciesRow.tsx";


const DefaultPage = () => {

    return (
        <Container maxWidth="md" style={{ marginTop: "50px" }}>
            <Paper elevation={3} style={{ padding: "40px" }}>
                <Typography variant="h3" align="center" gutterBottom>
                    Plata utilităților
                </Typography>

                <Typography
                    variant="body1"
                    align="center"
                    style={{ marginBottom: "30px", lineHeight: 1.6 }}
                >
                    Platforma noastră vă permite să plătiți rapid și în siguranță facturile la utilități
                    precum apă, energie electrică, gaze, internet și multe altele. Totul este simplu,
                    eficient și accesibil de oriunde!
                </Typography>

                <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
                    <NTPLogo color="#ffffff" version="horizontal" secret="145864" />

                    <Box display="flex" flexDirection="row" gap={2} justifyContent="center">
                        <img
                            src="https://anpc.ro/wp-content/uploads/2022/07/SAL-PICTOGRAMA.png"
                            height="60px"
                            alt="SAL pictogramă"
                        />
                        <img
                            src="/anpc.png"
                            height="60px"
                            alt="ANPC pictogramă"
                        />
                    </Box>
                </Box>

                <Box marginTop="40px">
                    <PoliciesRow />
                </Box>

                <Typography
                    variant="caption"
                    textAlign="center"
                    display="block"
                    style={{ marginTop: "30px", color: "#777" }}
                >
                    MYCO SOLUTIONS SRL. CUI 46600305. Contact: contact@plata-utilitati.ro
                </Typography>
            </Paper>
        </Container>

    );
};


export default DefaultPage;