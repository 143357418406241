import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {HelmetProvider} from "react-helmet-async";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {SettingsProvider, SettingsValueProps} from "rentzz";
import ConfigurationProvider from "./ConfigurationProvider.tsx";
import "./locales/i18n.ts"
import {RouterProvider} from "react-router-dom";
import {LicenseInfo} from "@mui/x-date-pickers-pro";
import {GrowthBook, GrowthBookProvider} from "@growthbook/growthbook-react";
import router from "./router.tsx";

const queryClient = new QueryClient();

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE ?? "")
export const defaultSettings: SettingsValueProps = {
    themeMode: "light",
    themeDirection: "ltr",
    themeContrast: "default",
    themeLayout: "horizontal",
    themeColorPresets: "default",
    themeStretch: false,
}

export const growthbook = new GrowthBook({
    apiHost: "https://flags-api.rentzz.app",
    clientKey: import.meta.env.VITE_APP_ENVIRONMENT_ID,
    subscribeToChanges: true,
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <HelmetProvider>
          <GrowthBookProvider growthbook={growthbook}>
              <QueryClientProvider client={queryClient}>
                  <SettingsProvider defaultSettings={defaultSettings}>
                      <ConfigurationProvider>
                          <RouterProvider router={router} />
                      </ConfigurationProvider>
                  </SettingsProvider>
              </QueryClientProvider>
          </GrowthBookProvider>
      </HelmetProvider>
  </React.StrictMode>,
)
