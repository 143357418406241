import './App.css'
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { LoadingIcon, useIsMobile, useLocales} from "rentzz";
import { useEffect} from "react";
import {Settings} from "luxon";
import MobileView from "./components/MobileView.tsx";
import DesktopView from "./components/DesktopView.tsx";
import {useSearchParams} from "react-router-dom";
import {redirectToPaymentMutation, useOrderDetailsQuery} from "./utils.ts";
import Table from "./components/Table.tsx";
import CardsList from "./components/CardsList.tsx";

function App() {
    const isMobile = useIsMobile()
    const { currentLang } = useLocales()
    const [search] = useSearchParams()
    const { isFetching} = useOrderDetailsQuery(search.get("code"),search.get("tenantId"))
    const {mutateAsync: redirectToPaymentPage, isPending} = redirectToPaymentMutation()

    useEffect(() => {
        Settings.defaultLocale = currentLang.value
    }, [currentLang])

    useEffect(() => {
        if(search.get("paymentStatus") != null){
            localStorage.setItem("language", search.get("language") ?? "")
        }
    }, [])

    if (isFetching || isPending)
    return <LoadingIcon/>

    return (
      <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale={search.get("language") ?? localStorage.getItem("language")}
          localeText={{start: "Move in date", end: "Move out date"}}
      >
          {isMobile ?
              <MobileView
                  content={<CardsList/>}
                  onAction={async () => {
                      if (search.get("code") == null) return
                      await redirectToPaymentPage({
                          code: search.get("code")!,
                          tenantId: search.get("tenantId")!
                      })
                  }}
                  actionText={"continue_to_payment"}
                  isBackButton={true}
                  isLoading={isPending}

              /> :
              <DesktopView
                  content={<Table/>}
                  onAction={async () => {
                      if (search.get("code") == null) return
                      await redirectToPaymentPage({
                          code: search.get("code")!,
                          tenantId: search.get("tenantId")!
                      })
                  }}
                  actionText={"continue_to_payment"}
                  isBackButton={true}
                  isLoading={isPending}
              />
          }
      </LocalizationProvider>
  )
}

export default App
