import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Api from "./Api.ts";

export enum PaymentStatus{
    Success,
    Pending,
    Failed
}
export enum Currencies {
    EUR = "EUR",
    RON = "RON",
    USD = "USD",
}
export interface ItemType {
    id: string
    title: string
    description: string,
    price: number,
    currency: Currencies
}

export interface CompanyData {
    logoUrl: string,
    name: string,
    schedule: string,
    location: string,
    phone: string
}
export interface OrderDetails {
    firstName: string,
    lastName: string,
    items:ItemType[]
    country: string
    county: string
    city: string
    address: string
    zipCode: string
    phoneNumber: string
    email: string
    languageCode: string
    orderId: string
}

export const useOrderDetailsQuery = (key: string | null, tenantId: string | null) => {
    return useQuery({
        staleTime: Infinity,
        queryKey: ["order-details"],
        queryFn: () => Api.fetchOrderDetails(key, tenantId),
        enabled: !!key
    })
}

export const useCompanyDetailsQuery = (tenantId: string | null) => {
    return useQuery({
        staleTime: Infinity,
        queryKey: ["company-details"],
        queryFn: () => Api.fetchCompanyDetails(tenantId),
        enabled: !!tenantId
    })
}

export const redirectToPaymentMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ tenantId, code }: { tenantId: string, code: string }) => Api.fetchRedirectPaymentPage(tenantId, code),

        onSettled: async (data, error) => {
            if (!error) {
                await queryClient.invalidateQueries({ queryKey: ["order-details"] })
                if (data) {
                    window.open(data, "_self")
                }
            }

        },
    })
}
