import {CustomDataGrid, useLocales} from "rentzz";
import {useMemo} from "react";
import {GridColDef} from "@mui/x-data-grid-pro";
import {ItemType, useOrderDetailsQuery} from "../utils.ts";
import {useSearchParams} from "react-router-dom";

export default function Table(){
    const { translate } = useLocales()
    const [search] = useSearchParams()
    const {data: orderDetails, isLoading} = useOrderDetailsQuery(search.get("code"),search.get("tenantId"))

    const columns = useMemo(() => {
        const columns: GridColDef<ItemType>[] = [
            {
                field: "title",
                type: "string",
                flex: 0.5,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("name"),
            },
            {
                field: "description",
                type: "string",
                minWidth: 90,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("description"),
            },
            {
                field: "price",
                type: "number",
                flex: 0.5,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.value"),
                valueFormatter: (value, row) => {
                    return `${value} ${row.currency}`
                },
            },

        ]

        return columns
    }, [translate])

    return(
        <CustomDataGrid
            isLoading={isLoading}
            columns={columns}
            rows={orderDetails?.items ?? []}
            idKey={"id"}
            totalCount={orderDetails?.items?.length}
            actionsColumn={[]}
            hidePagination={true}
            gridHeight={200}
            hideFooter={true}
            getRowId={(row) => row.id}
        />
    )
}