import {createBrowserRouter} from "react-router-dom";
import App from "./App.tsx";
import ErrorPage from "./components/ErrorPage.tsx";
import SuccessPage from "./components/SuccessPage.tsx";
import Terms from "./Terms.tsx";
import PendingPage from "./components/PendingPage.tsx";
import ClosingPage from "./components/ClosingPage.tsx";
import DefaultPage from "./DefaultPage.tsx";

const router = createBrowserRouter([
    {
        path: "/error",
        element: <ClosingPage><ErrorPage/></ClosingPage>,
    },
    {
        element: <App />,
        path: "/get-payment-details",
    },
    {
        element: <Terms />,
        path: "/terms",
    },
    {
        path: "/success",
        element: <ClosingPage><SuccessPage/></ClosingPage>,
    },
    {
        path: "/pending",
        element: <ClosingPage><PendingPage/></ClosingPage>,
    },
    {
        path: "/",
        element: <DefaultPage />,
    }
]);

export default router;