import {Page, useLocales} from "rentzz";
import {Box, CircularProgress, Typography, useTheme} from "@mui/material";

const PendingPage = () => {
    const {translate} = useLocales()
    const theme = useTheme();
    return <Page
        title={translate("pending")}
        sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
    >
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={2}
             color={theme.palette.grey.A700}>
            <CircularProgress size={"3rem"}/>
            <Box display={"flex"} flexDirection={"column"}>
                <Typography variant={"body2"} textAlign={"center"}>{translate("payment_pending")}</Typography>
            </Box>
        </Box>
    </Page>
}

export default PendingPage;