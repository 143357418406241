import {ReactNode} from "react"
import {MotionLazy, ThemeProvider} from "rentzz"

interface ConfigurationProviderProps {
    children: ReactNode
}

const themeError = {"lighter": "#FFE9D5","light": "#FFAC82","main": "#FF5630","dark": "#B71D18","darker": "#7A0916","contrastText": "#FFFFFF"};
const themeInfo = {"lighter": "#CAFDF5","light": "#61F3F3","main": "#00B8D9","dark": "#006C9C","darker": "#003768","contrastText": "#FFFFFF"};
const themePrimary = {"lighter": "#C8FAD6","light": "#5BE49B","main": "#00A76F","dark": "#007867","darker": "#004B50","contrastText": "#FFFFFF"};
const themeSecondary = {"lighter": "#EFD6FF","light": "#C684FF","main": "#8E33FF","dark": "#5119B7","darker": "#27097A","contrastText": "#FFFFFF"}
const themeSuccess = {"lighter": "#D3FCD2","light": "#77ED8B","main": "#22C55E","dark": "#118D57","darker": "#065E49","contrastText": "#ffffff"}
const themeWarning = {"lighter": "#FFF5CC","light": "#FFD666","main": "#FFAB00","dark": "#B76E00","darker": "#7A4100","contrastText": "#212B36"}
const ConfigurationProvider = ({ children }: ConfigurationProviderProps) => {

    return (
        <ThemeProvider
            primary={themePrimary}
            secondary={themeSecondary}
            info={themeInfo}
            success={themeSuccess}
            warning={themeWarning}
            error={themeError}
        >
            <MotionLazy>
                {children}
            </MotionLazy>
        </ThemeProvider>
    )
}

export default ConfigurationProvider
